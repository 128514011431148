<template>
  <div style="padding: 10px">
    <div style="margin: 10px 0"  v-if="this.userId === 1">
      <el-upload
              action="http://182.92.223.123:9090/saltedFishLevel/import"
              :on-success="handleUploadSuccess"
              :show-file-list=false
              :limit="1"
              accept='.xlsx'
              style="display: inline-block; margin: 0 10px">
        <el-button type="primary">导入</el-button>
      </el-upload>
      <!--<el-button type="primary" @click="exportUser">导出</el-button>-->
      <el-button type="primary" @click="add">新增</el-button>
    </div>
    <!--    搜索区域-->
    <div style="margin: 10px 0">
      <el-input v-model="search.level" placeholder="请输入主线关卡" @keyup.enter.native="load" style="width: 20%"
                clearable></el-input>
      <el-input v-model="search.boss" placeholder="请输入关卡BOSS" @keyup.enter.native="load" style="width: 20%"
                clearable></el-input>
      <el-button type="primary" style="margin-left: 5px" @click="load">查询</el-button>
      <el-button style="margin-left: 5px" @click="resetData">重置</el-button>
      <el-button @click="$router.push('/login')" v-if="this.userId == undefined">返回登录</el-button>
    </div>
    <el-table
            v-loading="loading"
            :data="tableData"
            border
            stripe
            style="width: 100%">
      <el-table-column
              prop="level"
              width="100px"
              label="主线关卡">
      </el-table-column>
      <el-table-column
              prop="boss"
              width="100px"
              label="关卡BOSS">
      </el-table-column>
      <el-table-column
              prop="levelType"
              width="100px"
              :formatter="levelTypeFormatter"
              label="关卡类型">
      </el-table-column>
      <el-table-column
              prop="first"
              width="120px"
              label="1号位">
      </el-table-column>
      <el-table-column
              prop="second"
              width="120px"
              label="2号位">
      </el-table-column>
      <el-table-column
              prop="third"
              width="120px"
              label="3号位">
      </el-table-column>
      <el-table-column
              prop="fourth"
              width="120px"
              label="4号位">
      </el-table-column>
      <el-table-column
              prop="fifth"
              width="120px"
              label="5号位">
      </el-table-column>
      <el-table-column
              prop="remark"
              show-overflow-tooltip
              label="备注">
      </el-table-column>
      <el-table-column label="操作" width="80px" v-if="this.userId === 1">
        <template #default="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin: 10px 0">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 20]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
      </el-pagination>
      <!--新增编辑表单-->
      <el-dialog title="新增/编辑" v-model="dialogVisible" width="30%">
        <el-form :model="form">
          <el-form-item label="关卡">
            <el-input v-model="form.level" style="width: 80%"></el-input>
          </el-form-item>
          <el-form-item label="BOSS">
            <el-input v-model="form.boss" style="width: 80%"></el-input>
          </el-form-item>
          <el-form-item label="关卡类型">
            <el-select v-model="form.levelType" filterable placeholder="请选择" style="width: 80%">
              <el-option
                      v-for="item in levelTypeIdDict"
                      :key="item.dictKey"
                      :label="item.dictValue"
                      :value="item.dictKey">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="阵容（空格分隔）">
            <el-input v-model="form.lineUp"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="form.remark"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>

  import request from "@/utils/request";

  export default {
    name: 'levelFormation',
    components: {},
    data() {
      return {
        userId: '',
        levelTypeIdDict: [],
        loading: true,
        search: {},
        query: {},
        currentPage: 1,
        pageSize: 10,
        total: 0,
        tableData: [],
        dialogVisible: false,
        form:{
          level:'',
          boss:'',
          levelType:'',
          lineUp:'',
          remark:''
        },
      }
    },
    created() {
      this.getLevelTypeIdDict("levelType");
      this.load()
      let userStr = sessionStorage.getItem("user") || "{}"
      let user = JSON.parse(userStr)
      this.userId = user.id;
    },
    methods: {
      // 点击新增按钮
      add() {
        this.dialogVisible = true
        this.form = {}
      },
      handleEdit(row) {
        this.form = JSON.parse(JSON.stringify(row))
        this.dialogVisible = true
      },
      save() {
        this.loading = true;
        if (this.form.id) {  // 更新
          request.post("/saltedFishLevel/update", this.form).then(res => {
            if (res.code === '0') {
              this.$message({
                type: "success",
                message: "更新成功"
              })
            } else {
              this.$message({
                type: "error",
                message: res.msg
              })
            }
            this.load() // 刷新表格的数据
            this.loading = false
            this.dialogVisible = false  // 关闭弹窗
          })
        } else {  // 新增
          request.post("/saltedFishLevel/save", this.form).then(res => {
            if (res.code === '0') {
              this.$message({
                type: "success",
                message: "新增成功"
              })
            } else {
              this.$message({
                type: "error",
                message: res.msg
              })
            }
            this.form = '';
            this.load(); // 刷新表格的数据
            this.loading = false
            this.dialogVisible = false  // 关闭弹窗
          })
        }
      },
      handleUploadSuccess(res) {
        if (res.code === "0") {
          this.$message.success("导入成功")
          this.load()
        }
      },
      exportUser() {
        location.href = "http://" + window.server.filesUploadUrl + ":9090/saltedFishLevel/export";
      },
      getLevelTypeIdDict(code) {
        request.get("/dict/all", {
          params: {
            code: code,
          }
        }).then(res => {
          this.levelTypeIdDict = res.data
        })
      },
      resetData() {
        this.search = {}
        this.load()
      },
      levelTypeFormatter(row) {
        if (row.levelType === null) {
          return "";
        }
        const list = this.levelTypeIdDict;
        if (list) {
          for (let i = 0; i < list.length; i++) {
            if (list[i].dictKey === row.levelType.toString()) {
              return list[i].dictValue
            }
          }
        }
      },
      load() {
        this.loading = true
        const searchForm = {...this.search}
        let obj = Object.assign(searchForm, this.query)
        let reqData = {
          ...obj
        }
        reqData.current = this.currentPage
        reqData.size = this.pageSize
        request.get("/saltedFishLevel/list", {
          params: reqData
        }).then(res => {
          this.loading = false
          this.tableData = res.data.records
          this.total = res.data.total
        })
      },
      handleSizeChange(pageSize) {   // 改变当前每页的个数触发
        this.pageSize = pageSize
        this.load()
      },
      handleCurrentChange(pageNum) {  // 改变当前页码触发
        this.currentPage = pageNum
        this.load()
      }
    }
  }
</script>
